import gsap from 'gsap'
import { find } from '../utils/dom'

const Grupo_Family_Office = () => {
	const page = find("[js-page='grupo-family-office']")
	if (!page) return

	const BTShowMore = find('.js-show-content')
	const Conteudo = find('.show-content')
	BTShowMore?.addEventListener(
		'click',
		(e) => {
			setTimeout(() => {
				Conteudo.scrollIntoView({ behavior: 'smooth' })
			}, 200)
		},
		false
	)

	const svg = find('#svgFamily')

	const relations = {
		sucessorio: { scale: 1.1, transformOrigin: '100% 100%', ease: 'none' },
		ativos: { scale: 1.1, transformOrigin: '0% 100%', ease: 'none' },
		riscos: { scale: 1.1, transformOrigin: '50% 0%', ease: 'none' },
		finaceiro: { scale: 1.1, transformOrigin: '50% 100%', ease: 'none' },
		aposentadoria: { scale: 1.1, transformOrigin: '0% 0%', ease: 'none' },
		planejamento: { scale: 1.1, transformOrigin: '100% 0%', ease: 'none' },
	}
	const relationsArr = Object.entries(relations)

	svg.addEventListener('mouseover', ({ target }) => {
		const { id } = target
		const isEl = relationsArr.find(([key]) => key === id) // target é um dos elementos da relacao
		const isChild = relationsArr.find(([key]) => target.closest(`#${key}`))?.[0] // target é um filho dos elementos da relacao
		const el = isEl || find(`#${isChild}`, svg)

		el?.id //
			? document.body.setAttribute('data-svg-active', el.id)
			: document.body.removeAttribute('data-svg-active')

		if (el?.id) gsap.to(el, relations[el.id])

		relationsArr.forEach(([key]) => {
			if (el?.id === key) return
			gsap.to(`#${key}`, { scale: 1 })
		})
	})

	svg.addEventListener('mouseleave', () => relationsArr.forEach(([key]) => gsap.to(`#${key}`, { scale: 1 })))

	window.gsap = gsap
}

export default Grupo_Family_Office
