import { find } from 'utils/dom'

import Swiper from 'swiper'
import gsap from 'gsap'
import { md } from '../main'

const cssVar = (el, varName) => getComputedStyle(el).getPropertyValue(varName)
let count = 0
export default function () {

	
	const Sobre = find("[js-page='Sobre']")
	if (!Sobre) return

	
	let swiperTime

	const nextEl = find('#swiper-time ~.setas .next')
	const prevEl = find('#swiper-time ~.setas .prev')
	const wrapperEl = find('#swiper-time .swiper-wrapper')

	swiperTime = new Swiper('#swiper-time', {
		centeredSlides: true,
		slidesPerView: 'auto',
		spaceBetween: md ? 12 : 40,
		allowTouchMove: md ? true : false,
		slideToClickedSlide: true,
		navigation: {
			nextEl,
			prevEl,
		},
		on: {
			// init: function (e) {
			// 	console.log('swiper-time init', e)
			// },
			// update: function (e) {
			// 	console.log('swiper-time update', e)
			// },
			slideChange: function (e) {
				const wrapperEl = find('#swiper-time .swiper-wrapper')

				window.scrollTo({
					top: wrapperEl.offsetTop - 100,
					left: 0,
					behavior: 'smooth',
				})
				// window.scrollY
				// debugger
			},
			// setTransition: function (e) {
			// 	console.log('swiper-time setTransition', e)
			// 	const slideActive = e.slides[e.realIndex]
			// },
			resize: function (e) {
				// swiperTime && swiperTime.destroy()
				// swiperTime && swiperTime.updateSize()
			},
			beforeResize: function (e) {
				// swiperTime && swiperTime.destroy()
				// swiperTime.init()
			},
			// realIndexChange: function (e) {

			// }

			transitionStart: function (e) {
				prevEl.style.pointerEvents = 'none'
				nextEl.style.pointerEvents = 'none'

				const slideActive = e.slides[e.realIndex]
				wrapperEl.style.transitionDuration = 300 + 'ms'

				const slidesWithinActive = e.slides.filter((slide) => !slide.classList.contains('swiper-slide-active'))
				const textSlidesWithinActive = slidesWithinActive.map((i) => i.querySelector('.text-swiper-time'))
				const texto = slideActive.querySelector('.text-swiper-time')
				const wrapperOffsetTop = wrapperEl.offsetTop
				const scrollTop = String(document.documentElement.getBoundingClientRect().top).replace('-', '')
				const slideActiveImgPB = find('[js-img-pb]', slideActive)
				const slideActiveImgAzul = find('[js-img-azul]', slideActive)
				const slidesWithinActiveImgAzul = slidesWithinActive.map((i) => i.querySelector('[js-img-azul]'))
				// console.log(Number(scrollTop) - 100, wrapperOffsetTop)
				// if (Number(scrollTop) + 100 < wrapperOffsetTop && count > 1) return
				// count++

				// seto a transicao para o wrapper nao pular quando der update

				const slideWidth = cssVar(wrapperEl, '--slide-width')
				const slideHeight = cssVar(wrapperEl, '--slide-height')
				const slideActiveWidth = cssVar(wrapperEl, '--slide-active-width')
				const slideActiveHeight = cssVar(wrapperEl, '--slide-active-height')
				const textTranslate = cssVar(wrapperEl, '--text-translate')

				const tl = gsap
					.timeline({ overwrite: true, ease: 'none' })
					.to(slideActive, { width: slideActiveWidth, height: slideActiveHeight, duration: 0.2, delay: 0.1 })
					.to(slideActiveImgAzul, { opacity: 1, duration: 0.2 }, '@label1-=0.2')
					.to(texto, { x: 0, opacity: 1, duration: 0.4, ease: 'expo.out' }, '@label1')
					.to(slidesWithinActiveImgAzul, { opacity: 0, duration: 0.2 }, '@label1-=0.2')
					.to(textSlidesWithinActive, { x: textTranslate, opacity: 0, duration: 0 }, '@label1-=0.2')
					.to(slidesWithinActive, { width: slideWidth, height: slideHeight, duration: 0.2 }, '@label1-=0.2')
					.call(
						() => {
							swiperTime && swiperTime.update()
							swiperTime && swiperTime.updateSize()
							wrapperEl.style.transitionDuration = 0
							setTimeout(() => {
								nextEl.style.removeProperty('pointer-events')
								prevEl.style.removeProperty('pointer-events')
							}, 100)
						},
						'',
						'@label1-=0.1'
					)

				// gsap.to(slideActive, { width: 550, height: '100vh' })
				// gsap.to(slideActive, { width: 550, height: '100vh' })

				// console.log('swiper-time transitionEnd', e)
			},
			// transitionEnd: function (e) {
			// 	swiperTime && swiperTime.update()
			// 	// swiperTime && swiperTime.updateSize()
			// 	wrapperEl.style.transitionDuration = 0
			// 	setTimeout(() => {
			// 		nextEl.style.removeProperty('pointer-events')
			// 		prevEl.style.removeProperty('pointer-events')
			// 	}, 100)
			// },
		},
		// effect: 'creative',
		// creativeEffect: {
		// 	prev: {
		// 		// will set `translateZ(-400px)` on previous slides
		// 		translate: [0, 0, -400],
		// 	},
		// 	next: {
		// 		// will set `translateX(100%)` on next slides
		// 		translate: ['100%', 0, 0],
		// 	},
		// },
	})

	window.swiperTime = swiperTime
}

/* 

const wrapperEl = find('#swiper-time .swiper-wrapper')
	const slideActive = e.slides[e.realIndex]
	const slidesWithinActive = e.slides.filter((slide) => !slide.classList.contains('swiper-slide-active'))
	const textSlidesWithinActive = slidesWithinActive.map((i) => i.querySelector('.text-swiper-time'))
	const texto = slideActive.querySelector('.text-swiper-time')
	swiperTime && (wrapperEl.style.transitionDuration = 700 + 'ms')
	const slideWidth = getComputedStyle(wrapperEl).getPropertyValue('--slide-width')
	const slideHeight = getComputedStyle(wrapperEl).getPropertyValue('--slide-height')
	const slideActiveWidth = getComputedStyle(wrapperEl).getPropertyValue('--slide-active-width')
	const slideActiveHeight = getComputedStyle(wrapperEl).getPropertyValue('--slide-active-height')

	gsap
		.timeline({ overwrite: true, ease: 'power1.out' })
		.to(slideActive, { width: slideActiveWidth, height: slideActiveHeight, duration: 0.2 })
		.to(texto, { x: 0, duration: 0.2 })
		.to(textSlidesWithinActive, { x: -400, duration: 0.1 }, '<')
		.to(slidesWithinActive, { width: slideWidth, height: slideHeight, duration: 0.2 }, '<')

		.call(() => {
			swiperTime && swiperTime.update()
			swiperTime && swiperTime.updateSize()
			swiperTime && (wrapperEl.style.transitionDuration = 0)
		})

	// gsap.to(slideActive, { width: 550, height: '100vh' })
	// gsap.to(slideActive, { width: 550, height: '100vh' })

	console.log('swiper-time transitionEnd', e)
*/
