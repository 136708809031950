import { gsap } from 'gsap'
import { findAll } from '../utils/dom.js'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import animateFrom from './animateFrom.js'

export default function animaAoScroll() {
	findAll('[js-anima]').forEach(function (elem) {
		const start = 'top bottom+=100px'
		const trigger = elem.getAttribute('data-trigger') || elem

		ScrollTrigger.create({
			trigger: elem,
			once: true,
			start,
			refreshPriority: 10,
			anticipatePin: 1,
			// markers: { startColor: 'blue', endColor: 'white', fontSize: '18px', fontWeight: 'bold', indent: 20 },
			onEnter: function () {
				// console.log('🟡 ScrollTrigger', { el: trigger })
				animateFrom(elem)
			},
			// onEnterBack: function () {
			// 	animateFrom(elem, -1)
			// },
			// onLeave: function () {
			// 	hide(elem)
			// } // assure that the element is hidden when scrolled into view
		})
	})
}
