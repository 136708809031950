import axios from 'axios'
import { find } from '../utils/dom'
import moment from 'moment'

export default function Midia() {
	console.log('Midia')
	const page = find("[js-page='midia']")
	const btn = find('[js-click-more]')
	const wrapper = find('[js-wrapper]')
	if (!page) return

	let pageNumber = 2
	async function onclickMoreBtn(e) {
		e.preventDefault()
		try {
			const { data } = await axios.get(`/api/post/?page=${pageNumber++}`)
			console.log({ posts: data })

			
			data?.results.forEach((post) => {
				console.log(post)
				wrapper.insertAdjacentHTML('beforeend', postTemplate(post));
			})
			if (!data.results?.length) btn.classList.add('hidden')
		} catch (error) {
			console.log(error)
		}
	}
	console.log(btn)
	btn?.addEventListener('click', onclickMoreBtn)
}
function postTemplate(post) {
	const _d = new Date(post.data_criacao)
	const date = moment(_d).format('DD[.]MM[.]yyyy')
	return `
	<article class="w-full Post swiper-slide md:w-[325px] md:min-w-[325px] md:max-w-[325px]">
		<a href="/blog/${post.slug}">
			<picture class="h-[17.5rem] relative overflow-hidden w-full block">
				<img class="absolute top-0 left-0 w-full h-full object-cover hover:scale-105  transition-transform"
					src="${post.thumbnail}" alt="" loading="lazy">
			</picture>
		</a>
		<a href="/blog/${post.slug}">
			<h3 class="my-[1.875rem] md:mt-16 md:mb-10 text-headline-1 md:text-headline-2 text-primary-dark two-lines min-h-64"
				title="${post.titulo}"> 
				${post.titulo}
			</h3>
		</a>
		<p class="text-paragraph-2 text-primary-dark md:text-paragraph-3" title="${post.resumo}">${post.resumo}</p>
		<span class="text-caps-1 text-primary-dark mt-16 md:mt-12 inline-block">${date}</span>
	</article>
	`
}
