import axios from 'axios'
import showToastify from '../utils/toastify';
import "toastify-js/src/toastify.css"
import { find } from 'utils/dom'
import IMask from 'imask';

export default function PageContato(){




    const contato = find("[js-page='contato']")
	if (!contato) return

    const element = document.querySelector('#form-contato input[name=telefone]')
const maskOptions = {
    mask: [
        {
          mask: '(00) 0000-0000'
        },
        {
          mask: '(00) 00000-0000'
        }
    ]
};
const mask = IMask(element, maskOptions);


    const contatoFormSubmit = document.querySelector('#form-contato')
    contatoFormSubmit && contatoFormSubmit.addEventListener('submit', function(event) {
        event.preventDefault();
        disabledBtn()
        // const form_data = {
        //     nome: document.querySelector('#form-contato input[name=nome]').value,
        //     email: document.querySelector('#form-contato input[name=email]').value,
        //     telefone: document.querySelector('#form-contato input[name=telefone]').value,
        //     mensagem: document.querySelector('#form-contato textarea[name=mensagem]').value,
        //     arquivo: document.querySelector('#form-contato input[name=arquivo]').files

        // }

        const formData = new FormData();
        formData.append('nome', document.querySelector('#form-contato input[name=nome]').value);
        formData.append('email', document.querySelector('#form-contato input[name=email]').value);
        formData.append('telefone', document.querySelector('#form-contato input[name=telefone]').value);
        formData.append('mensagem', document.querySelector('#form-contato textarea[name=mensagem]').value);
        formData.append('arquivo', document.querySelector('#form-contato input[name=arquivo]').files[0]);
       
        const url = "/fale-conosco/"
        axios.post(url, formData)
            .then((response) => showMessage(response))
            .catch((response) => showMessage(response));
    });

    function showMessage(response) {
        const message = response.data ? response.data.message : response.message
        showToastify(message)
        contatoFormSubmit.reset()
        setTimeout(enabledBtn, 1000)
    }

    const btnSubmit = document.querySelector("#form-contato button[type=submit]")

    function disabledBtn() {
        btnSubmit.disabled = true
        btnSubmit.classList.add("disabled")
    }

    function enabledBtn() {
        btnSubmit.disabled = false
        btnSubmit.classList.remove("disabled")
    }
   
}