import { find } from "../utils/dom";

const Grupo_Banking = () =>{
    const page = find("[js-page='grupo-banking']");

    if(!page) return;

    const swiper_cambio = new Swiper(".swiper-cambio", {
        slidesPerView: "auto",
        spaceBetween: 16,
        mousewheel: true,
    });

    const swiper_credito = new Swiper(".swiper-credito", {
        slidesPerView: "auto",
        spaceBetween: 16,
        mousewheel: true,
    });

    const BTShowMore = find(".js-show-content");
    const Conteudo = find(".show-content");
    BTShowMore?.addEventListener("click", (e)=>{
        setTimeout(()=>{
            Conteudo.scrollIntoView({ behavior: 'smooth' });
        }, 200)

        
    }, false)


}

export default Grupo_Banking;