import { find } from 'utils/dom'
import {
	animationBigNumbers,
	animationFuturo,
	animationIntro,
	animationMidia,
	animationProposito,
	animationVisao,
} from '../animation/animateSetup'
import { md } from '../main'
import Swiper from 'swiper'

export default function () {
	const Home = find("[js-page='home']")
	if (!Home) return

	console.log('Home')

	animationIntro()
	animationBigNumbers()
	//animationProposito()
	animationFuturo()
	animationMidia()
	animationVisao()

	if (md) {
		const swiperMidia = new Swiper('#swiper-midia', {
			slidesPerView: 'auto',
			spaceBetween: 20,
		})
	}
}
