import { find } from "../utils/dom";
import Swiper from "swiper";

const Grupo_Seguros = () =>{

    const page = find("[js-page='grupo-seguros']");


    if(!page) return;



    const swiper_seguros_corporate = new Swiper(".swiper-seguros-corporate", {
        slidesPerView: "auto",
        spaceBetween: 16,
        mousewheel: true,
    });

    const swiper_seguros_personal = new Swiper(".swiper-seguros-personal", {
        slidesPerView: "auto",
        spaceBetween: 16,
        mousewheel: true,
    });

    const BTShowMore = find(".js-show-content");
    const Conteudo = find(".show-content");
    BTShowMore?.addEventListener("click", (e)=>{
        setTimeout(()=>{
            Conteudo.scrollIntoView({ behavior: 'smooth' });
        }, 200)

        
    }, false)




}

export default Grupo_Seguros;