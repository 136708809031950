import axios from 'axios'
import axiosConfig from './modules/axiosConfig'
import PageHome from './pages/Home.js'
import PageLogin from './pages/Login'
import PageSobre from './pages/Sobre.js'
import PageMidia from './pages/Midia.js'
import PageGrupoSeguros from "./pages/Grupo_Seguros.js"
import UseMouseOrKeyboard from './modules/UseMouseOrKeyboard'
import DesignSystem from './DesignSystem/DesignSystem'
import { register } from 'swiper/element/bundle'
import logs from './utils/logs'
import ripple from './modules/ripple'
import formErros from './modules/formErros'
import animaAoScroll from './animation/animaAoScroll'
import animateSetup from './animation/animateSetup'
import Swiper from 'swiper'
import PageContato from './pages/Contato'
import PageGrupoInvestmentBanking from "./pages/Group_Investment_Banking.js"
import PageGrupoBanking from "./pages/Grupo_Banking.js"
import PageGrupoFamilyOffice from "./pages/Group_Family_Office.js"

import Botao_Grupo from './modules/BotaoGrupo'
// import SvgUse from "./utils/SvgUse";

export const colors = {
	white: '#f3f7fa',
	primaryLight: '#1471a0',
}

export const { white, primaryLight } = colors
export const md = matchMedia('(max-width: 1024px)').matches
export const DEV = import.meta.env.DEV
// Init
GLOBAL.DesignSystem = DesignSystem({})
register() // Swiper Web Component

// Config
axiosConfig({ csrf: GLOBAL.context?.csrf }, axios)
UseMouseOrKeyboard()
ripple()
formErros()
animateSetup()
PageHome()
PageSobre()
PageContato()
PageMidia()
PageGrupoSeguros()
PageGrupoInvestmentBanking()
PageGrupoBanking()
PageGrupoFamilyOffice()
Botao_Grupo()
animaAoScroll()



window.document.addEventListener('DOMContentLoaded', () => {
	jQuery.fn.FCInstagram.accessData = {
		accessToken:
			'IGQWRQMGpFekdSUG9vSk5SWktkN0UxUXJEbEVyQlR0WkpOMEpfQ3lZAeDVta1RWTzJLa2VYV1R4TjRjTXVtVEVjUGQtSlpyRzdJbl9JUEhBVmxySER4aDFfTWN1NWRQRUMwQkZAtZAnRseFY1cXBRUndWbkVDRjhpVFUZD', // Token
	}
	$('#instafeed').FCInstagram({
		max: 4, // A number between 1 and 25 of photos to show. Default: 9
		//autoplay: true, // Set autoplay video: true/false. Default: false
		complete: function () {
			// A callback function to execute after the display of the photos.
			const instaItems = document.querySelectorAll('#instafeed a')
			const instaWrapper = document.querySelector('#instafeed')

			const htmlSlide = [...instaItems]
				.map((item) => {
					return `
							<div class="swiper-slide">
									<span style="visibility:hidden">-</span>
									<div class="conteudo">
											${item.outerHTML}    
									</div>
							</div>
					`
				})
				.join('')

			instaWrapper.innerHTML = `
			<div class="swiper swiper-acompanhe-nos">
					<div class="swiper-wrapper">
							${htmlSlide}
					</div>
			</div>`

			const swiperAcompanhe = new Swiper('.swiper-acompanhe-nos', {
				slidesPerView: 1.2,
				spaceBetween: 8,
				breakpoints: {
					640: {
						slidesPerView: 2,
						spaceBetween: 8,
					},
					768: {
						slidesPerView: 3,
						spaceBetween: 8,
					},
					1024: {
						slidesPerView: 4,
						spaceBetween: 8,
					},
				},
			})
			console.log('completed', instaItems)
		},
	})
})

const mySwiper = new Swiper('.mySwiper', {
	slidesPerView: 1.2,
	spaceBetween: 8,
	breakpoints: {
		640: {
			slidesPerView: 2,
			spaceBetween: 8,
		},
		768: {
			slidesPerView: 3,
			spaceBetween: 8,
		},
		1024: {
			slidesPerView: 4,
			spaceBetween: 8,
		},
	},
})

const menuItems = document.querySelectorAll('div[js-menu-item]')

if (window.matchMedia('(max-width:1024px)').matches && menuItems) {
	menuItems.forEach((menuLink) => {
		menuLink.addEventListener('click', (e) => {
			e.preventDefault()
			const isAccordion = e.target.closest('div[js-accordion-item]')
			if (!isAccordion) {
				// If it's not an accordion, navigate to the link URL
				const linkElement = e.currentTarget.querySelector('a')
				if (linkElement) {
					window.location.href = linkElement.href
				}
			} else {
				// const accordionContent = isAccordion.querySelector('ul[js-accordion-content]')
				// // Create a new list item
				// const newItem = document.createElement('li');
				// newItem.className = 'text-paragraph-1 text-primary-light first:mt-32';
				// newItem.innerHTML = `
				// 	<a href="/grupo_family_office">
				// 		AAAAAAAAA
				// 	</a>
				// `;
				// // Append the new item to the accordion content
				// accordionContent.appendChild(newItem);
			}

		})
	})
}

// Find all accordion items
const accordionItems = document.querySelectorAll('dialog[js-modal="modalMenu"] [js-accordion-item]')

// Iterate through each accordion item
if (accordionItems) {
	accordionItems.forEach((accordionItem, index) => {
		const firstLink = accordionItem.querySelector('div a')

		if (firstLink) {
			// Create a new list item
			const newItem = document.createElement('li')
			newItem.className = 'text-paragraph-1 text-primary-light first:mt-32'
			newItem.innerHTML = `
                <a href="${firstLink.getAttribute('href')}">
                    ${firstLink.textContent}
                </a>
            `

			// Append the new item to the accordion content
			accordionItem.querySelector('ul').appendChild(newItem)
		} else {
			console.error(`No first link found for accordion item ${index + 1}`)
		}
	})
}

// Pages
PageLogin()?.init()

// utils logs
logs()
