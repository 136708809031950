import { find } from "../utils/dom";
import Swiper from "swiper";

const Group_Investment_Banking = () =>{
    const page = find("[js-page='grupo-investment-banking']");

    if(!page) return;

    const swiper_banking = new Swiper(".swiper-cards-banking",{
        slidesPerView: "auto",
        spaceBetween: 16,
        mousewheel: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'fraction',
        },
    })

    const BTShowMore = find(".js-show-content");
    const Conteudo = find(".show-content");
    BTShowMore?.addEventListener("click", (e)=>{
        setTimeout(()=>{
            Conteudo.scrollIntoView({ behavior: 'smooth' });
        }, 200)

        
    }, false)



}

export default Group_Investment_Banking;