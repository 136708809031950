import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { find, findAll, height, width } from '../utils/dom'
import { CustomEase } from 'gsap/all'
import { DEV, colors, md, primaryLight, white } from '../main'

export default function () {
	gsap.registerPlugin(ScrollTrigger)
	CustomEase.create('easeTransform', '0.26,0.67,0.48,0.917')
	animationBtn()

	window.animateMenu = animateMenu
	window.onCloseMenu = onCloseMenu
}

export function animationBtn() {
	const btns = findAll('[js-gsap-btn]')
	btns?.forEach((btn) => {
		const p = find('p', btn)
		const svg = find('svg', btn)
		if (!p || !svg) return

		const pWidth = width(p)

		gsap.set(p, { '--clip': '110%' })
		gsap.set(svg, { x: -pWidth - 16 })

		const tl = gsap
			.timeline({ paused: true, overwrite: true })
			.from(svg, { duration: 0, rotate: 0, x: -pWidth - 16 })
			.from(p, { duration: 0, '--clip': '110%' })
			.to(svg, { x: -8, duration: 0.3, scale: 0.75, ease: 'power3.out', rotate: 180 }, '@timer1')
			.to(p, { '--clip': 0, duration: 0.3, ease: 'power1.out' }, '@timer1')
		// .to(btn, { x: -pWidth, duration: 0.3, ease: 'power1.out' }, '@timer1')

		btn.addEventListener('mouseenter', () => !tl.isActive() && tl.timeScale(1).play())
		btn.addEventListener('mouseleave', () => tl.timeScale(1.5).reverse())
	})
}

export function animationIntro() {
	const menuSize = getComputedStyle(document.documentElement).getPropertyValue('--menu-size')
	const wrapper = find('[js-intro="wrapper"]')
	const wrapperImg = find('[js-intro="wrapper-img"]')
	const wrapperImg2 = find('#intro-img-2')
	const tlClip = gsap.timeline({ paused: true, overwrite: true })
	const tlImages = gsap.timeline({ paused: true, overwrite: true })
	if (!wrapper) return

	tlClip
		.from(wrapper, { '--clip': md ? '45vh' : '55vh' }) //
		.to(wrapper, { '--clip': '0', duration: 10, ease: 'none' }, '@juntos')

	tlImages
		.from(wrapperImg2, { opacity: 0 })
		.to(wrapperImg2, { opacity: 0.25 })
		.to(wrapperImg2, { opacity: 0.5 })
		.to(wrapperImg2, { opacity: 0.7 })
		.to(wrapperImg2, { opacity: 0.75 })
	//.to(wrapperImg2, { opacity: 0.9 })
	// .to(wrapperImg2, { opacity: 1 })

	ScrollTrigger.create({
		markers: DEV ? true : false,
		trigger: '#intro',
		pin: '#intro',
		start: `top+=1 top`,
		end: '+=35%',
		pinSpacing: true,
		anticipatePin: 1,
		animation: tlClip,
		onEnterBack: function () {
			tlClip.reverse()
		},
		scrub: 1,
	})

	ScrollTrigger.create({
		markers: DEV ? true : false,
		trigger: '#intro',
		start: `center-=45% top`,
		end: '+=25%',
		anticipatePin: 1,
		animation: tlImages,
		scrub: 1,
	})
}

export function animationBigNumbers() {
	const section = find('#big_numbers')
	const numbers = findAll('[js-big-number]', section)
	const texts = findAll('[js-big-number-text]', section)
	const itemLeft = findAll('.item-left', section)

	if (!section) return

	gsap.set(numbers, { color: 'rgb(var(--white))' })
	gsap.set(numbers, { opacity: 0, x: -50 })
	gsap.set(texts, { opacity: 0, x: -50 })

	const tlBigNumbers = gsap
		.timeline({ paused: true, overwrite: true }) //
		.to(numbers, { x: 0, ease: 'easeTransform', stagger: 0.15, delay: 0.3 }, '<')
		.to(numbers, { opacity: 1, ease: 'none', stagger: 0.15 }, '<')
		.to(texts, { x: 0, ease: 'easeTransform', stagger: 0.15, delay: 0.1 }, '<')
		.to(texts, { opacity: 1, ease: 'none', stagger: 0.15, delay: 0.1 }, '<')
		.to(itemLeft, { '--height': '2rem', stagger: 0.15 }, '-=0.5')

	// const tl = gsap
	// 	.timeline({ paused: true, overwrite: true })
	// 	// .from(section, { backgroundColor: 'transparent' })
	// 	// .to(section, { background: primaryLight })
	// 	.add(tlBigNumbers, '<')

	ScrollTrigger.create({
		markers: DEV ? true : false,
		trigger: section,
		start: md ? `top-=200%` : `top-=80%`,
		end: 50,
		pinSpacing: true,
		anticipatePin: 1,
		animation: tlBigNumbers,

		toggleActions: 'play none reverse',
	})

	// ScrollTrigger.create({
	// 	markers: DEV ? true:false,
	// 	trigger: section,
	// 	start: `top-=400`,

	// 	pinSpacing: true,
	// 	anticipatePin: 1,
	// 	animation: tlBigNumbers,
	// })
}

export function animationVisao() {
	const section = find('#visao')
	const title = find('[js-title]', section)
	const text = find('[js-text]', section)
	const btn = find('[js-btn]', section)
	if (!section) return

	gsap.set(title, { opacity: 0, y: -50 })
	gsap.set(text, { opacity: 0, y: -50 })
	gsap.set(btn, { opacity: 0, y: -50 })

	const tl = gsap
		.timeline({ paused: true, overwrite: true })
		.from(title, { duration: 0, opacity: 0, y: -50 })
		.from(text, { duration: 0, opacity: 0, y: -50 })
		.from(btn, { duration: 0, opacity: 0, y: -50 })
		.to([title, text, btn], { y: 0, ease: 'easeTransform', stagger: 0.1, delay: 0.1 }, '<')
		.to([title, text, btn], { opacity: 1, ease: 'none', stagger: 0.1, delay: 0.1 }, '<')

	ScrollTrigger.create({
		markers: DEV ? true : false,
		trigger: section,
		start: `top-=500 top`,
		end: 100,
		pinSpacing: true,
		anticipatePin: 1,
		animation: tl,

		toggleActions: 'play none reverse',
	})
}

export function animationProposito() {
	const section = find('#proposito')
	const title = find('[js-title]', section)
	const text = find('[js-text]', section)
	const img = find('[js-img]', section)

	if (!section) return
	gsap.set(section, { background: primaryLight })
	gsap.set(title, { /* opacity: 0, y: -100, */ color: white })
	gsap.set(text, { /* opacity: 0, y: -100, */ color: white })
	gsap.set(img, { /* opacity: 0, y: -100, */ filter: 'brightness(3.4)' })

	const tl = gsap
		.timeline({ paused: true, overwrite: true })
		.to([title, img, text], { y: 0, ease: 'easeTransform', stagger: 0.1, delay: 0.1 }, '<')
		.to([title, img, text], { opacity: 1, ease: 'none', stagger: 0.1, delay: 0.1 }, '<')

	const tl2 = gsap
		.timeline({ paused: true, overwrite: true })
		.from(section, { background: primaryLight, duration: 0 })
		.from(title, { color: white, duration: 0 })
		.from(text, { color: white, duration: 0 })
		.from(img, { filter: 'brightness(3.4)', duration: 0 })
		.to(img, { filter: 'brightness(1)', duration: 0.5 }, '@Inicial')
		.to(section, { background: white, duration: 0.5 }, '@Inicial')
		.to(text, { color: '#1b2634', duration: 0.5 }, '@Inicial')
		.to(title, { color: '#1b2634', duration: 0.5 }, '@Inicial')

	ScrollTrigger.create({
		markers: DEV ? true : false,
		trigger: section,
		start: `top-=50% top`,
		end: 50,
		pinSpacing: true,
		anticipatePin: 1,
		animation: tl,
	})

	ScrollTrigger.create({
		trigger: section,
		start: `top-=65% top`,
		end: 100,
		pinSpacing: true,
		anticipatePin: 1,
		animation: tl2,
		markers: DEV ? true : false,
		toggleActions: 'play none reverse',
	})
}

export function animationFuturo() {
	const section = find('#rascunho')
	const title = find('[js-title]', section)
	const text = find('[js-text]', section)
	const btn = find('[js-btn]', section)
	if (!section) return

	gsap.set(title, { opacity: 0, y: -50 })
	gsap.set(text, { opacity: 0, y: -50 })
	gsap.set(btn, { opacity: 0, y: -50 })

	const tl = gsap
		.timeline({ paused: true, overwrite: true })
		.from(title, { duration: 0, opacity: 0, y: -50 })
		.from(text, { duration: 0, opacity: 0, y: -50 })
		.from(btn, { duration: 0, opacity: 0, y: -50 })
		.to([title, text, btn], { y: 0, ease: 'easeTransform', stagger: 0.1, delay: 0.1 }, '<')
		.to([title, text, btn], { opacity: 1, ease: 'none', stagger: 0.1, delay: 0.1 }, '<')

	ScrollTrigger.create({
		markers: DEV ? true : false,
		trigger: section,
		start: `top-=500 top`,
		end: 100,
		pinSpacing: true,
		anticipatePin: 1,
		animation: tl,

		toggleActions: 'play none reverse',
	})
}

export function animationMidia() {
	const section = find('#midia')
	const title = find('[js-title]', section)
	const text = find('[js-text]', section)
	const posts = findAll('.Post', section)
	if (!section) return

	gsap.set(title, { opacity: 0, y: -50 })
	gsap.set(text, { opacity: 0, y: -50 })
	gsap.set(posts, { opacity: 0, y: -50 })

	const tl = gsap
		.timeline({ paused: true, overwrite: true })
		.to([title, text, posts], { y: 0, ease: 'easeTransform', stagger: 0.1, delay: 0.1 }, '<')
		.to([title, text, posts], { opacity: 1, ease: 'none', stagger: 0.1, delay: 0.1 }, '<')

	ScrollTrigger.create({
		markers: DEV ? true : false,
		trigger: section,
		start: `top-=500 top`,
		end: 50,
		pinSpacing: true,
		anticipatePin: 1,
		animation: tl,

		toggleActions: 'play none reverse',
	})
}

const modal = find('[js-modal="modalMenu"]')
const menuItem = findAll('[js-menu-item]', modal)
const logoAndItem = findAll('[js-logo], [js-menu-btn]', modal)

const tlMenu = gsap
	.timeline({ paused: true }) //
	.to(logoAndItem, { opacity: 1, y: 0, duration: 0.5, delay: 0.2 }, '@juntos')
	.to(menuItem, { opacity: 1, y: 0, stagger: 0.1, duration: 0.5, delay: 0.3 }, '@juntos')

function animateMenu() {
	gsap.set(logoAndItem, { opacity: 0, y: 50 })
	gsap.set(menuItem, { opacity: 0, y: 50 })
	tlMenu.timeScale(1).play()
}

function onCloseMenu() {
	const accordionTitle = find('[js-modal="modalMenu"] [js-accordion-title]')
	const accordionItem = find('[js-modal="modalMenu"] [js-accordion-item]')
	// if (accordionItem.classList.contains('active')) {
	// 	accordionTitle.instance.toggle()
	// }
	tlMenu.timeScale(3).reverse()
}

// function gsapHover() {

// 	const els = findAll('[js-gsap="mouseenter"')

// 	if (!els.length) return

// 	els.forEach(item => {
// 		const dataFrom = item.getAttribute('data-from') ? JSON.parse(item.getAttribute('data-from')) : {}
// 		const dataTo = item.getAttribute('data-to') ? JSON.parse(item.getAttribute('data-to')) : {}
// 		// const dataFn = item.getAttribute('data-gsap-fn') ? JSON.parse(item.getAttribute('data-gsap-fn')) : null
// 		const tl = gsap
// 			.timeline({ paused: true })
// 			.fromTo(item, dataFrom, dataTo)

// 		item.addEventListener('mouseenter', () => {
// 			tl.play()
// 		})

// 		item.addEventListener('mouseout', () => {
// 			tl.reverse()
// 		})

// 	})
// }
