import { findAll } from "../utils/dom";

const Botao_Grupo = () =>{
    const Botao = findAll('.js-show-content');

    if(!Botao) return;

    Botao.forEach((single_botao)=>{
        single_botao.addEventListener("click", (e)=>{
            e.preventDefault();

            const all_box_content = findAll('.show-content');
            if(all_box_content){
                all_box_content.forEach((box)=>{
                    box.classList.remove('no-content');
                    box.style.maxHeight = `${box.scrollHeight}px !important`
                })
            }
        })
    })
}

export default Botao_Grupo;